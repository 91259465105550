<template>
    <div class="Area">
        <main-header menuActive="statistics" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>미배정 사유별 통계</h2>
                </div>
                <div class="row searchBtn">
                    <div class="pull-right">
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>
                    </div>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">기간</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_start"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_end"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchListFunc">조회</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-profile">
                            <tbody>
                            <tr>
                                <th class="field text-center font-weight-bold">일자</th>
                                <th class="field text-center font-weight-bold">병원병상부족</th>
                                <th class="field text-center font-weight-bold">생치병상부족</th>
                                <th class="field text-center font-weight-bold">보건소답변지원</th>
                                <th class="field text-center font-weight-bold">생치마감</th>
                                <th class="field text-center font-weight-bold">고령(70세이상)</th>
                                <th class="field text-center font-weight-bold">본인거부</th>
                                <th class="field text-center font-weight-bold">미의뢰</th>
                                <th class="field text-center font-weight-bold">기타</th>
                                <th class="field text-center font-weight-bold">합계</th>
                            </tr>
                            <tr v-for="(data, index) in dataList" :key="index">
                                <td class="text-center">{{ data.fregDate }}</td>
                                <td class="text-center text-link" @click="searchDetailFunc('병원병상부족', data.fregDate, 'G0501')">{{ data.nonAssign1 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('생치병상부족', data.fregDate, 'G0502')">{{ data.nonAssign2 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('보건소답변지원', data.fregDate, 'G0508')">{{ data.nonAssign3 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('생치마감', data.fregDate, 'G0509')">{{ data.nonAssign4 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('고령(70세이상)', data.fregDate, 'G0510')">{{ data.nonAssign5 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('본인거부', data.fregDate, 'G0503')">{{ data.nonAssign6 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('미의뢰', data.fregDate, 'G0511')">{{ data.nonAssign7 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('기타' , data.fregDate, 'G0505')">{{ data.nonAssign8 | comma }} 명</td>
                                <td class="text-center text-link">{{ data.nonAssign9 | comma }} 명</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <th class="field text-center font-weight-bold">총계</th>
                                <td class="text-center">{{ sum_G0501 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0502 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0508 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0509 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0510 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0503 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0511 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0505 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0512 | comma }} 명</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'Area',
    data() {
        return {
            ko : ko,
            sch_date_start : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
            sch_date_end : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)).format('YYYY-MM-DD'),
            dataList : [],

            sum_G0501 : 0,
            sum_G0502 : 0,
            sum_G0508 : 0,
            sum_G0509 : 0,
            sum_G0510 : 0,
            sum_G0503 : 0,
            sum_G0511 : 0,
            sum_G0505 : 0,
            sum_G0512 : 0, // 합계
        }
    },
    components: { mainHeader, mainFooter, datepicker },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
    },
    // $el 생성후
    mounted() {
        if (this.$route.params.sch_date_start){
            this.sch_date_start = this.$route.params.sch_date_start;
            this.sch_date_end = this.$route.params.sch_date_end;

            this.searchListFunc()
        }else{
            this.searchListFunc()
        }
    },
    methods: {
        // 리스트 검색
        searchListFunc(){
            let params = `{"fromRegDt": "${this.dateToString(this.sch_date_start)}", "toRegDt": "${this.dateToString(this.sch_date_end)}"}`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/nonAssign?params=' + queryString, null, 'searchListFuncResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'statisticsUnassign');
            formData.append('sch_date_start', this.$moment(this.sch_date_start).format('YYYY-MM-DD'));
            formData.append('sch_date_end', this.$moment(this.sch_date_end).format('YYYY-MM-DD'));
            formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxPost('', formData,'searchListFuncResult');
            */
        },
        searchListFuncResult(response) {
            let result = response;
            this.dataList = result.list;

            let sum_G0501 = 0
            let sum_G0502 = 0
            let sum_G0508 = 0
            let sum_G0509 = 0
            let sum_G0510 = 0
            let sum_G0503 = 0
            let sum_G0511 = 0
            let sum_G0505 = 0

            result.list.forEach(function (item) {
                sum_G0501 = parseInt(item.nonAssign1) +  sum_G0501;
                sum_G0502 = parseInt(item.nonAssign2) +  sum_G0502;
                sum_G0508 = parseInt(item.nonAssign3) +  sum_G0508;
                sum_G0509 = parseInt(item.nonAssign4) +  sum_G0509;
                sum_G0510 = parseInt(item.nonAssign5) +  sum_G0510;
                sum_G0503 = parseInt(item.nonAssign6) +  sum_G0503;
                sum_G0511 = parseInt(item.nonAssign7) +  sum_G0511;
                sum_G0505 = parseInt(item.nonAssign8) +  sum_G0505;
            });

            this.sum_G0501 = sum_G0501;
            this.sum_G0502 = sum_G0502;
            this.sum_G0508 = sum_G0508;
            this.sum_G0509 = sum_G0509;
            this.sum_G0510 = sum_G0510;
            this.sum_G0503 = sum_G0503;
            this.sum_G0511 = sum_G0511;
            this.sum_G0505 = sum_G0505;
            this.sum_G0512 = sum_G0501 + sum_G0502 + sum_G0508 + sum_G0509 + sum_G0510 + sum_G0503 + sum_G0511 + sum_G0505;
        },
        excelDownFunc(){
            console.log("엑셀다운로드");
            /*
            let url=this.rootUrl+'/_excel/excel_statistics_unassign_reason.php.php?1=1';
            if(this.sch_date_start && this.sch_date_end){
                url += '&sch_date_start='+this.$moment(this.sch_date_start).format('YYYY-MM-DD');
                url += '&sch_date_end='+this.$moment(this.sch_date_end).format('YYYY-MM-DD');
            }
            this.excelExport(url);
            */
            
            let params = `{
                "fromRegDt": "${this.dateToString(this.sch_date_start)}", 
                "toRegDt": "${this.dateToString(this.sch_date_end)}"
            }`;

            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/nonAssign?params=' + queryString, null, 'downloadExcel');

        },
        downloadExcel(response) {
            if (!response || !response.list || response.list.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.list; // 데이터 가져오기
            console.log(dataList);
            let excelData = [];

            let totalnonAssign1 = 0; // 총계 초기화
            let totalnonAssign2 = 0; // 총계 초기화
            let totalnonAssign3 = 0; // 총계 초기화
            let totalnonAssign4 = 0; // 총계 초기화
            let totalnonAssign5 = 0; // 총계 초기화
            let totalnonAssign6 = 0; // 총계 초기화
            let totalnonAssign7 = 0; // 총계 초기화
            let totalnonAssign8 = 0; // 총계 초기화
            let totalnonAssign9 = 0; // 총계 초기화

            // 엑셀 헤더 추가
            excelData.push(['일자', '병원병상부족', '생치병상부족', '보건소답변지원', '생치마감', '고령(70세이상)', '본인거부', '미의뢰', '기타', '합계']);

            // 엑셀 데이터 추가
            dataList.forEach((data, index) => {
                excelData.push([
                    data.fregDate, 
                    data.nonAssign1, 
                    data.nonAssign2, 
                    data.nonAssign3, 
                    data.nonAssign4, 
                    data.nonAssign5,
                    data.nonAssign6,
                    data.nonAssign7,
                    data.nonAssign8,
                    data.nonAssign9 
                ]);

                // 각 열의 데이터를 합산하여 총계에 누적
                totalnonAssign1 += data.nonAssign1;
                totalnonAssign2 += data.nonAssign2;
                totalnonAssign3 += data.nonAssign3;
                totalnonAssign4 += data.nonAssign4;
                totalnonAssign5 += data.nonAssign5;
                totalnonAssign6 += data.nonAssign6;
                totalnonAssign7 += data.nonAssign7;
                totalnonAssign8 += data.nonAssign8;
                totalnonAssign9 += data.nonAssign9;
            });

            // 총계 행 추가
            excelData.push(['총계', totalnonAssign1, totalnonAssign2, totalnonAssign3, totalnonAssign4, 
            totalnonAssign5, totalnonAssign6, totalnonAssign7, totalnonAssign8, totalnonAssign9]); 
            
            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정통계_미배정사유별.xlsx');
        },
        // 상세페이지
        searchDetailFunc(title, date, idx){
            let data={};
            data.sch_date_start = this.sch_date_start;
            data.sch_date_end = this.sch_date_end;
            data.title = title;
            data.date = date;
            data.idx = idx;
            data.sch_type = 'unassign';
            data.sch_select_type = '';
            console.log(data);

            this.$router.push({ name: 'AdminStatisticsDetail', params: {data} })
        },
        dateToString(dateString) {
            // const dateString = "Fri Jan 01 2021 09:00:00 GMT+0900 (한국 표준시)";
            const date = new Date(dateString); // Date 객체 생성
            const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`; // 년, 월, 일을 추출하여 원하는 형식으로 조합

            // 월과 일이 한 자리 수일 경우 앞에 0을 붙이는 함수
            function padZero(num) {
                return num < 10 ? '0' + num : num;
            }

            return formattedDate;
        },
    }
}
</script>

<style scoped>
</style>
